import * as _ from 'lodash';

export interface IDeliveryPoint {
  _id: string;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted?: boolean;
  latitude: number;
  longitude: number;
  address: string;
  customerMail: string[];
  phone: string[];
  deliveryName: string;
  customerName: string[];
  details: string;
  timeFrom: string;
  timeTo: string;
}

export class DeliveryPointModel implements IDeliveryPoint {
  _id: string;
  address: string;
  companyId: string;
  createdAt: Date;
  customerMail: string[];
  customerName: string[];
  phone: string[];
  deliveryName: string;
  details: string;
  isDeleted: boolean;
  latitude: number;
  longitude: number;
  updatedAt: Date;
  timeFrom: string;
  timeTo: string;
  checked: boolean;
  totalRegistries: number;
  isPrincipal: boolean;
  index: number;

  get warehouse(): string {
    try {
      const json = JSON.parse(this.details);
      return json['warehouse'] || '';
    } catch (e) {
      return this.details;
    }
  }

  set warehouse(zone: string) {
    this.details = JSON.stringify({
      warehouse: this.warehouse,
    });
  }

  constructor(
    id?: string,
    address?: string,
    companyId?: string,
    createdAt?: Date,
    customerMail?: string[],
    customerName?: string[],
    phone?: string[],
    deliveryName?: string,
    details?: string,
    isDeleted?: boolean,
    latitude?: number,
    longitude?: number,
    updatedAt?: Date,
    timeFrom?: string,
    timeTo?: string,
    checked?: boolean,
    totalRegistries?: number,
    isPrincipal?: boolean,
    index?: number
  ) {
    this._id = id;
    this.address = address;
    this.companyId = companyId;
    this.createdAt = createdAt;
    this.phone = phone;
    this.customerMail = customerMail;
    this.customerName = customerName;
    this.phone = phone;
    this.deliveryName = deliveryName;
    this.details = details;
    this.isDeleted = isDeleted;
    this.latitude = latitude;
    this.longitude = longitude;
    this.updatedAt = updatedAt;
    this.checked = checked;
    this.totalRegistries = totalRegistries;
    this.isPrincipal = isPrincipal;
    this.index = index;
  }

  static fromMap(map: any): DeliveryPointModel {
    if (map && map['customerMail'] === '') {
      map['customerMail'] = [];
    }
    if (map && map['customerName'] === '') {
      map['customerName'] = [];
    }
    if (map && map['phone'] === '') {
      map['phone'] = [];
    }

    return Object.assign(new DeliveryPointModel(), map);
  }
  static fromListMap(list: Array<any>): Array<DeliveryPointModel> {
    return list.map(x => this.fromMap(x));
  }

  /**
   * Get warehouse from serialized details property
   *
   * @returns String | warehouse
   */
  getWarehouse(): String {
    try {
      const parsed = JSON.parse(this.details);
      return _.get(parsed, 'warehouse', 'N/A');
    } catch (e) {
      return _.get(this.details, 'warehouse', 'N/A');
    }
  }

  getComments(): String {
    try {
      const parsed = JSON.parse(this.details);
      const comments =
        parsed.comments && parsed.comments.length > 0
          ? parsed.comments.join(' | ')
          : _.get(parsed, 'comments', 'N/A');
      return comments;
    } catch (e) {
      return _.get(this.details, 'comments', 'N/A');
    }
  }

  getDeliveryName() {
    return `${this.deliveryName}`.trim();
  }

  /**
   * Representation of windows time destination ins avaliable for business logic
   *
   * @returns timecfrom - timeTo
   */
  getTimeRange() {
    if (this.timeFrom && this.timeTo) {
      return this.timeFrom + ' - ' + this.timeTo;
    }
    return 'N/A';
  }

  /**
   * Return DeliveryPoint plain object
   *
   * @returns DeliveryPoint plain object
   */
  toMap() {
    let newDetails = '{"warehouse":"N/A"}';

    if (this.warehouse) {
      newDetails = JSON.stringify({
        warehouse: this.warehouse,
      });
    }

    return _.chain({
      _id: this._id,
      companyId: this.companyId,
      latitude: this.latitude,
      longitude: this.longitude,
      deliveryName: this.deliveryName,
      customerName: this.customerName,
      details: newDetails,
      customerMail: this.customerMail,
      timeFrom: this.timeFrom,
      timeTo: this.timeTo,
      phone: this.phone,
      warehouse: this.warehouse,
      address: this.address,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    })
      .omitBy(_.isNil)
      .value();
  }
}
